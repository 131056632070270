.single-item-wrapper {
  h6,
  p {
    margin: 0;
  }
  .reviews-block {
    .reviews-btn {
      background-color: #23155b;
    }
  }
  form {
    textarea {
      outline: none;
    }
    .rating {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin-top: 10px;
      gap: 0.3rem;
      --stroke: #666;
      --fill: #ffc73a;
      input {
        display: none;
      }
      label {
        cursor: pointer;
      }
      svg {
        width: 2.5rem;
        height: 2.5rem;
        overflow: visible;
        fill: transparent;
        stroke: var(--stroke);
        stroke-linejoin: bevel;
        stroke-dasharray: 12;
        animation: idle 4s linear infinite;
        transition: stroke 0.2s, fill 0.5s;
      }
      @keyframes idle {
        from {
          stroke-dashoffset: 24;
        }
      }
      label:hover svg {
        stroke: var(--fill);
      }
      input:checked ~ label svg {
        transition: 0s;
        animation: idle 4s linear infinite, yippee 0.75s backwards;
        fill: var(--fill);
        stroke: var(--fill);
        stroke-opacity: 0;
        stroke-dasharray: 0;
        stroke-linejoin: miter;
        stroke-width: 8px;
      }
      @keyframes yippee {
        0% {
          transform: scale(1);
          fill: var(--fill);
          fill-opacity: 0;
          stroke-opacity: 1;
          stroke: var(--stroke);
          stroke-dasharray: 10;
          stroke-width: 1px;
          stroke-linejoin: bevel;
        }

        30% {
          transform: scale(0);
          fill: var(--fill);
          fill-opacity: 0;
          stroke-opacity: 1;
          stroke: var(--stroke);
          stroke-dasharray: 10;
          stroke-width: 1px;
          stroke-linejoin: bevel;
        }

        30.1% {
          stroke: var(--fill);
          stroke-dasharray: 0;
          stroke-linejoin: miter;
          stroke-width: 8px;
        }

        60% {
          transform: scale(1.2);
          fill: var(--fill);
        }
      }
    }
  }
  .single-item-wrapper_wr {
    position: relative;
    z-index: 1;
    padding: 10px 0 50px;
    h2 {
      color: #23155b;
    }
    .catalog__card__colors span {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      transition: ease-in-out 0.3s;
      &.active {
        background: #ffffff;
        -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        cursor: auto;
        border: 3px solid #23155b;
      }
      &.disabled {
        opacity: 0.7;
        pointer-events: none;
      }
      img {
        width: 100%;
      }
    }
    .single-item-wrapper__types {
      padding-left: 105px;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      @media screen and (max-width: 992px) {
        padding-left: 0;
      }
      @media screen and (max-width: 768px) {
        padding: 0 25px;
      }
      .single-item-wrapper__description {
        margin-bottom: 15px;
      }
      .catalog__card__colors {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
      .catalog__card__sizes {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        gap: 10px;
        height: 29px;
        .activeSize {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          margin-bottom: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          overflow: hidden;
          cursor: pointer !important;
          &.active{
            background: #ffffff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
            cursor: auto;
            border: 3px solid #23155b;
          }
        }
        @media screen and (max-width: 576px) {
          height: 20px;
        }
        p {
          text-transform: lowercase;
        }
      }
      .catalog__card_add {
        margin-bottom: 20px;
        .catalog__card_add_qty {
          > div {
            display: flex;
            P {
              font-size: 10px;
            }
            button {
              width: 45px;
              height: 45px;
            }
            span {
              width: 60px;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px solid;
              border-top: 1px solid;
              border-radius: 0;
              box-shadow: none;
              transition: none;
              &:hover {
                transform: none;
              }
            }
          }
        }
        .oldPrice {
          text-decoration: line-through !important;
          color: rgb(130, 123, 123);
          font-weight: 100;
        }
      }
      .addToCartBtn {
        height: 45px;
        display: flex;
        padding: 7px 10px;
        justify-content: center;
        align-items: center;
        border: 1px solid #3c4151;
        color: #23155b;
        gap: 7px;
        font-weight: 500;
        @media screen and (max-width: 576px) {
          font-size: 0.8125rem;
        }
        &:hover {
          background: #23155b;
          border: 1px solid #23155b;
          box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
          color: #fff !important;
        }
      }
    }

    .slick-prev {
      top: 36%;
      &:before {
        content: url("../../assets/images/homePageImg/revArrL.svg");
      }
      @media screen and (max-width: 576px) {
        left: 15px;
        width: 30px;
        height: 30px;
        z-index: 1;
      }
    }
    .slick-next {
      top: 36%;
      &:before {
        content: url("../../assets/images/homePageImg/revArrR.svg");
      }
      @media screen and (max-width: 576px) {
        right: 15px;
        width: 30px;
        height: 30px;
        z-index: 1;
      }
    }
    .slick-dots {
      position: relative;
      overflow-x: scroll;
      overflow-x: auto;
      white-space: nowrap;
      bottom: -5px;
      &::-webkit-scrollbar {
        height: 5px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #23155b;
        border-radius: 10px;
      }
      li {
        width: 60px;
        height: 85px;
        img {
          width: 100%;
          object-fit: cover;
        }
        video {
          width: 100%;
          object-fit: cover;
        }
        a {
          display: flex;
          height: 100%;
        }
        &.slick-active {
          border: 1px solid #1a202c;
        }
      }
    }
    .slick-slider {
      .slick-track {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 576px) {
          gap: 0px !important;
        }
        .slick-slide {
          height: 650px;
          @media screen and (max-width: 576px) {
            height: 450px;
          }
          &:focus-visible {
            outline: none !important;
          }
          > div {
            height: 100%;
            &:focus-visible {
              outline: none !important;
            }
            > div {
              height: 100%;
              &:focus-visible {
                outline: none !important;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &:focus-visible {
                  outline: none !important;
                }
              }
            }
          }
          figure {
            width: 100%;
            background-color: #ffffff00;
            .slider-wrapper__image {
              @media screen and (max-width: 576px) {
                height: 436px;
              }
            }
          }
        }
      }
    }
  }
}
