.about-block-bg {
  height: 60vh;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    height: 50vh;
  }
  .about-left-slider {
    width: 80%;
    height: 100%;
    @media screen and (max-width: 576px) {
      width: 70%;
    }
    .slick-slider {
      height: 100%;
    }
    .slick-track {
      height: 100%;
    }
    .slick-list {
      height: 100%;
    }
    .slick-dots {
      display: none !important;
    }
    .slick-slide {
      > div {
        height: 100%;
      }
      .one {
        background-image: url(https://res.cloudinary.com/drdqjr8r6/image/upload/v1700658780/bg%20images/azazefir_an_image_that_describes_wholesale_ecommerce_wear_cloth_074ceb0a-2eaf-4d91-9e8a-20f5064c0842_ofbqbt.png);
        height: 100%;
        > div {
          width: 90%;
          height: 90%;
          padding: 20px;
          background: rgba(255, 255, 255, 10%);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          z-index: 1;
          border: 3px solid;
          border-image: linear-gradient(
            50deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.86),
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.86)
          );
          border-image-slice: 1;
          > div {
            width: 60%;
            align-items: flex-start;
            @media screen and (max-width: 576px) {
              width: 100%;
              align-items: center;
            }
            h1 {
              color: #fff;
              text-transform: uppercase;
              font-family: "Roboto";
              font-size: 40px !important;
              @media screen and (max-width: 992px) {
                font-size: 24px !important;
                text-align: start !important;
              }
              @media screen and (max-width: 768px) {
                font-size: 16px !important;
                text-align: start !important;
              }
              @media screen and (max-width: 576px) {
                font-size: 16px !important;
                text-align: center !important;
              }
            }
            p {
              font-family: "Roboto";
              font-size: 25px !important;
              color: #fff;
              @media screen and (max-width: 992px) {
                font-size: 12px !important;
              }
            }
            a {
              color: #fff !important;
              border: 1px solid #fff;
              border: 1px solid #fff;

              width: initial;
              height: initial;
              padding: 10px 20px;
              &:hover {
                background: transparent;
                color: #23155b;
                border: 1px solid #fff;
                background: #23155b;
                border: 1px solid #23155b;
              }
              @media screen and (max-width: 768px) {
                font-size: 12px !important;
                padding: 5px;
              }
            }
          }
          > figure {
            width: 40%;
            @media screen and (max-width: 1200px) {
              width: 50%;
            }
            @media screen and (max-width: 576px) {
              display: none !important;
            }
            img {
              transition: transform 0.3s ease-in-out;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
      .two {
        background-image: url(https://res.cloudinary.com/drdqjr8r6/image/upload/v1700659277/bg%20images/azazefir_an_image_that_describes_wholesale_ecommerce_wear_cloth_b618b427-95ca-4cb5-bc11-832ed6ee36c9_g928gx.png);
        > div {
          width: 90%;
          height: 90%;
          padding: 18px;
          background: rgba(255, 255, 255, 10%);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          z-index: 1;
          border: 3px solid;
          border-image: linear-gradient(
            50deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.86),
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.86)
          );
          border-image-slice: 1;
          h1 {
            color: #fff;
            text-align: center;
            z-index: 1;
            @media screen and (max-width: 992px) {
              font-size: 24px !important;
            }
            @media screen and (max-width: 768px) {
              font-size: 16px !important;
            }
          }
        }
      }
      .about-bg {
        background-position: center;
        background-size: cover;
        width: 95%;
        height: 60vh;
        position: relative;
        @media screen and (max-width: 768px) {
          height: 100%;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(rgba(0, 0, 0, 50%), rgba(0, 0, 0, 50%));
        }
      }
    }
    .slick-next {
      right: 15px;
      z-index: 2;
      @media screen and (max-width: 576px) {
        display: none !important;
      }
    }
    .slick-prev {
      left: 15px;
      z-index: 2;
      @media screen and (max-width: 576px) {
        display: none !important;
      }
    }
    .slick-prev,
    .slick-next {
      background-color: #ffffff55;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.86);
    }
  }
  .about-right-cards {
    width: 20%;
    @media screen and (max-width: 576px) {
      width: 30%;
    }
    > div {
      height: 50%;
      position: relative;
      overflow: hidden;
      figure {
        height: 100%;
        overflow: hidden;
        width: 100%;
        margin-bottom: 0;
        a {
          height: 100%;
          width: 100%;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
      figcaption {
        z-index: 1;
        position: absolute;
        margin: 0 auto;
        width: 90%;
        bottom: 20px;
        text-align: center;
        padding: 20px;
        background: rgba(255, 255, 255, 10%);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border: 3px solid;
        border-image: linear-gradient(
          50deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.86),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.86)
        );
        border-image-slice: 1;
        color: #fff;
        font-weight: 500;
        transition: transform 0.3s ease-in-out;
        opacity: 0;
        @media screen and (max-width: 576px) {
          width: 95%;
          opacity: 1;
          padding: 2px;
          font-size: smaller;
          transition: none;
        }
      }
      &:hover figcaption {
        transform: translateY(-65%);
        opacity: 1;
        @media screen and (max-width: 576px) {
          transform: none;
        }
      }
    }
  }
}
