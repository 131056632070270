@font-face {
  font-family: "Roboto-mono";
  src: local("Roboto-mono"),
    url("../../../assets/fonts/roboto-mono/RobotoMono-Medium.ttf")
      format("truetype");
}

.incrementing-counter {
  font-family: "Roboto-Mono", sans-serif !important;
  margin: 50px 0 30px;
  @media screen and (max-width: 576px) {
    margin: 50px 0px 0px 0px;
  }
  > div {
    overflow-x: scroll;
    white-space: nowrap;
    justify-content: center;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .counter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 180px;
    padding: 30px 0;
    background: #ffffff;

    @media screen and (max-width: 1200px) {
      min-width: 170px;
      -webkit-min-width: 170px;
    }
    @media screen and (max-width: 576px) {
      min-width: 100px;
      -webkit-min-width: 100px;
    }

    img {
      width: 90px;
      height: 90px;
      @media screen and (max-width: 992px) {
        width: 70px;
        height: 70px;
      }
      @media screen and (max-width: 576px) {
        width: 45px;
        height: 45px;
      }
    }
    span {
      font-size: small;
      color: #000000;
    }
    .counter {
      font-size: 50px;
      color: #23155b;
      @media screen and (max-width: 992px) {
        font-size: 35px;
      }
      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
      @media screen and (max-width: 576px) {
        font-size: 15px;
      }
    }
  }
}
