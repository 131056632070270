@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

@font-face {
  font-family: "Roboto-regular";
  src: local("Roboto-regular"),
    url("./assets/fonts/ubuntu/Ubuntu-Regular.ttf")
      format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto-regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  background-color: rgba(246, 246, 246, 1) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
