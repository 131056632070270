.about-us-page__comand {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  h5 {
    color: #23155b;
  }
  &__photos {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 30px;
    figure {
      width: 25%;
      display: inline-block;
      margin-bottom: 45px !important;
      margin-right: 20px;
      @media screen and (max-width: 576px) {
        width: 50%;
      }
      img {
        width: 100%;
        height: 100%;
      }
      figcaption{
        padding: 5px;
        text-align: center;
      }
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      margin: 40px 0;
    }
    figure {
      margin-bottom: 50px;
      img {
        width: 100%;
      }
    }
  }
}
