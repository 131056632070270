.css-1lpkgyo {
  .css-qlig70 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      overflow: hidden;
      cursor: pointer !important;
      &.active{
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        cursor: auto;
        border: 3px solid #23155b;
      }
    }
  }
}
