.catalog-brands-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 380px;
  margin-bottom: 120px;
  gap: 10px;
  @media screen and (max-width: 1400px) {
    grid-auto-rows: 340px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 350px;
  }
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 250px;
  }
  @media screen and (max-width: 768px) {
    grid-auto-rows: 180px;
  }
  @media screen and (max-width: 576px) {
    grid-auto-rows: 230px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 280px;
  }
}
