.icon-style {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  .icon {
    font-size: 1.1rem;
    color: orange;
    cursor: pointer;
    @media screen and (max-width: 992px) {
      font-size: 1rem;
    }
  }
  .icon-half {
    color: gold; /* color of half star */
    position: relative;
  }
  
  .icon-half::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: gold; /* color of half star */
    z-index: -1;
  }
  .empty-icon {
    font-size: 2.6rem;
  }
}
