.header {
  position: relative;
  z-index: 110;
  padding: 0;
  color: #3c4151;
  background-color: rgb(255, 255, 255);
  @media screen and (max-width: 1200px) {
    padding-bottom: 10px;
  }
  @media screen and (max-width: 992px) {
    padding-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    padding-top: 8px;
  }
  .css-hlg657:hover,
  .css-hlg657[data-hover] {
    background: none;
  }
  .css-3lq0dh {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  > div {
    display: grid;
    grid-template-areas: "catMenu logo nav nav nav nav nav nav nav cart cart cart";
    @media screen and (max-width: 768px) {
      grid-template-areas:
        "nav nav nav nav nav nav"
        "logo logo cart cart cart cart";
    }
    .menu-category {
      grid-area: catMenu;
      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }
    .header__brand__logo {
      grid-area: logo;
      margin: 5px 0;
    }
    .header__navs {
      grid-area: nav;
    }
    .header__cart {
      grid-area: cart;
    }
  }
  .header__navs {
    width: 60%;
    @media screen and (max-width: 768px) {
      width: initial;
    }
    form {
      > div {
        input {
          border: 2px solid;
          border-image: linear-gradient(45deg, #f64d4d, #4d5ef6);
          border-image-slice: 1;
          border-radius: 0;
        }
        .css-10dip6r:focus-visible,
        .css-10dip6r[data-focus-visible] {
          box-shadow: none;
        }
      }
      button {
        &:hover {
          background: none;
        }
      }
    }

    a {
      font-weight: lighter;
    }

    .css-1t3zy1p {
      font-weight: lighter;
    }
    .css-1t3zy1p:active,
    .css-1t3zy1p[data-active] {
      background: none;
    }
    .css-1t3zy1p:hover,
    .css-1t3zy1p[data-hover] {
      background: none;
    }

    > nav:first-child {
      .css-r6z5ec {
        .css-1kfu8nn {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 5rem;
          gap: 10px;
          padding: 10px;
          a {
            button {
              color: #fff;
              background: linear-gradient(
                  rgba(0, 0, 0, 0.5),
                  rgba(0, 0, 0, 0.5)
                ),
                var(--dynamic-background);
              background-position: center;
              background-size: cover;
              -webkit-background-size: cover;
              background-size: cover;
              background-repeat: no-repeat;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 5rem;
              border-radius: 0.4rem;
              &.active {
                font-weight: 600;
              }
            }
          }
        }
      }
      @media screen and (max-width: 992px) {
        display: none !important;
      }
    }
  }

  div {
    .toggle__menu {
      display: none;
      @media screen and (max-width: 992px) {
        display: block;
      }
    }
    .hidden {
      display: none;
    }
    .toggleMenu {
      position: absolute;
      top: 97px;
      left: 0;
      bottom: 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      height: max-content;
      padding: 30px;
      display: none;
      border-bottom: 1px solid #b9b8bb;
      @media screen and (max-width: 992px) {
        display: block;
      }
      @media screen and (max-width: 768px) {
        top: 150px;
      }
      @media screen and (max-width: 576px) {
        top: 120px;
      }
      > div {
        position: relative;
        z-index: 2;
        height: 5rem;
        border-radius: 0.4rem;
        padding: 0 !important;
        > div {
          padding: 10px;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url(https://import.cdn.thinkific.com/1/app_store/1651602076_bb92abf74c5803404171f25f02bf96e2.png);
          background-position: center;
          background-size: cover;
          -webkit-background-size: cover;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: inherit;
          width: 100%;
          height: 100%;
          button {
            > span {
              color: #ffffff;
            }
          }
        }
      }
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 5rem;
        padding: 0;
        gap: 10px;
        margin-bottom: 0.7rem;
        a {
          color: #ffffff !important;
          display: flex;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 10px;
          width: 100%;
          height: 100%;
          border-radius: 0.4rem;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            var(--dynamic-background);
          background-position: center;
          background-size: cover;
          -webkit-background-size: cover;
          background-size: cover;
          background-repeat: no-repeat;
          @media screen and (max-width: 576px) {
            font-size: 18px !important;
          }

          &:hover {
            color: #23155b !important;
          }
        }
        .links-lightBlue {
          color: #fff !important;
        }
      }
    }

    ul {
      list-style-type: none;
    }
    .header__brand__logo {
      svg {
        @media screen and (max-width: 576px) {
          display: block;
        }
        @media screen and (min-width: 576px) {
          display: none;
        }
      }
      img {
        @media screen and (max-width: 576px) {
          display: none;
        }
        @media screen and (min-width: 576px) {
          display: block;
        }
      }
    }
    .header__brand__logo,
    .header__cart {
      cursor: pointer;
      display: flex;
      > img {
        width: 31px;
        @media screen and (max-width: 576px) {
          width: 31px;
          height: 31px;
        }
      }
      a {
        position: relative;
        > img {
          @media screen and (max-width: 576px) {
            width: 31px;
            height: 31px;
          }
        }
      }
      > div {
        &:first-child {
          > div {
            padding: 10px;
            gap: 12px;
          }
        }
      }
      .chakra-menu__menu-button {
        padding: 5px;
        @media screen and (max-width: 992px) {
          padding: 0;
          background-color: transparent;
        }
      }
      .chakra-menu__menu-button:nth-child(6) {
        @media screen and (max-width: 992px) {
          display: none;
        }
      }
      .header__cart__count {
        position: absolute;
        top: -5px;
        color: #fff;
        right: -13px;
      }
    }
    .header__brand__logo {
      img {
        @media screen and (max-width: 576px) {
          width: 80px !important;
        }
      }
    }
  }
  .cart__btn {
    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.css-1o3pyl4 {
  transform: none !important;
  svg {
    color: #231a74;
    width: 20px;
    height: 20px;
  }
}

.css-7p9xsp:focus-visible,
.css-7p9xsp[data-focus-visible] {
  z-index: 1;
  border-color: #23155b !important;
  box-shadow: 0 0 0 1px #23155b !important;
}

.header-drawer {
  padding-bottom: 10px;
  border-right: 1px solid #c2c2c2;
  button {
    background: none;
    width: 100%;
    justify-content: start;
    gap: 10px;
    font-weight: lighter;
    &.active {
      background: #e2e8f0;
    }
  }
}
.css-1o3pyl4 {
  max-width: 300px !important;
}

.male-drawer {
  position: absolute;
  left: 300px;
  top: 0;
  bottom: 0;
  background: #fff;
  height: 100vh;
  width: 250px;
  overflow: auto;
  border-right: 1px solid #c2c2c2;
  ul {
    font-weight: lighter;
    list-style: none;
    padding-left: 0;
    li {
      width: 100%;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 6px;
      &:hover {
        background-color: #e2e8f0;
      }
    }
  }
}
.child-sub-drawer {
  left: 550px;
}
#style__scroll__profile::-webkit-scrollbar-thumb {
  background-color: #b9b8bb;
  border-radius: 10px;
}
