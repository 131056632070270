.catalogs-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div:first-child {
    @media screen and (max-width: 576px) {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 5px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
      }
    }
  }
  h3 {
    @media screen and (max-width: 576px) {
      font-size: 1.15rem;
    }
  }
  .catalog-cards-wrapper-filter-block {
    ul {
      list-style: none;
      padding-left: 0;
      overflow-y: auto;
      li {
        white-space: nowrap;
        border: 1px solid rgb(221 221 221);
        padding: 5px 10px;
        margin-bottom: 5px;
        cursor: pointer;
        &.active {
          background-color: #23155b;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    #style__scroll::-webkit-scrollbar-thumb {
      background-color: rgb(221 221 221) !important;
      border-radius: 10px;
    }
  }
  .sort-popup__name {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px;
    width: max-content;
    @media screen and (max-width: 576px) {
      margin-bottom: 10px;
    }
  }
  .catalog__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    @media screen and (max-width: 576px) {
      gap: 5px;
    }

    // Grid view
    &.grid-view {
      &:not(:empty) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 380px;
        @media screen and (max-width: 1400px) {
          grid-auto-rows: 340px;
        }
        @media screen and (max-width: 1200px) {
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: 350px;
        }
        @media screen and (max-width: 992px) {
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: 250px;
        }
        @media screen and (max-width: 768px) {
          grid-auto-rows: 180px;
        }
        @media screen and (max-width: 576px) {
          grid-auto-rows: 230px;
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 400px) {
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 280px;
        }
      }
    }

    // Column view
    &.column-view {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 40%;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
      .catalog__card {
        height: auto;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}

.view-mode-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.css-1ews2c8 {
  button {
    &.active {
      font-weight: 500;
      color: #23155b;
    }
  }
}
