.productionInfoPage__container {
  .productionInfoPage__header {
    border-radius: 0.7rem;
    @media screen and (max-width: 576px) {
      background-size: cover !important;
    }
    &__logo {
      background: #fff;
      border-radius: 50%;
    }
    &__info {
      h1,
      p,
      span {
        color: #fff !important;
      }
      h1 {
        font-size: 2rem !important;
        text-align: start !important;
      }
    }
  }
  .productionInfoPage__images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 400px;
    gap: 10px;
    @media screen and (max-width: 1200px) {
      grid-auto-rows: 300px;
    }
    @media screen and (max-width: 992px) {
      grid-auto-rows: 250px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 220px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 200px;
    }
    figure {
      overflow: hidden;
      border-radius: 0.7rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  svg {
    color: #118638;
  }
  .css-1mbanyi[aria-selected="true"] {
    color: #118638;
  }

  .productionInfoPage__spec {
    > div {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .productionInfoPage__ads {
    width: 50%;
    > div {
      height: 300px;
      background: #b8b4b4;
      border-radius: 0.7rem;
      padding: 10px;
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: 100% !important;
      @media screen and (max-width: 576px) {
        background-size: cover !important;
      }
      h2,
      p {
        color: #fff;
        text-align: center;
      }
    }
    a {
      color: #fff !important;
      height: 50px;
      background: #23155b;
      border-radius: 0.7rem;
      &:hover {
        color: #23155b !important;
        background: transparent;
        border: 1px solid #23155b;
      }
    }
  }
  .css-fgp5ep {
    padding-bottom: 5px;
    @media screen and (max-width: 576px) {
      overflow-x: scroll;
      white-space: nowrap;
      &::-webkit-scrollbar {
        height: 5px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #23155b;
        border-radius: 10px;
      }
    }
  }
}
