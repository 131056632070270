.delivery-container {
  .delivery-about {
    &::before {
      content: "";
      background-image: url("https://res.cloudinary.com/drdqjr8r6/image/upload/v1700217909/bg%20images/azazefir_This_chat_provides_a_convenient_and_effective_way_to_f_8255d0a3-7baa-4ffb-8d24-eb105656967a_dhqmjk.png");
      background-size: cover;
      background-position: 100% 65%;
      background-repeat: no-repeat;
      opacity: 0.3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
    padding: 30px 0 50px;
    p {
      text-align: center;
      margin-top: 80px;
    }
  }
  .main-content {
    padding: 80px 30px;
    &__grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: 90px;
      position: relative;
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(3, 1fr);
      }
      figure {
        margin-bottom: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            aspect-ratio: 3/2;
            width: 60%;
            object-fit: contain;
            @media screen and (max-width: 576px) {
              width: 90%;
            }
          }
        }
      }
    }
  }
}
