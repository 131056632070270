.design-department__boss {
  margin: 80px 0;
  .design-dep__bg {
    background-color: #fff;
    padding: 50px 0;
    > div {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      > figure {
        width: 30%;
        @media screen and (max-width: 768px) {
          width: 50%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      > div {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media screen and (max-width: 768px) {
          width: 100%;
          align-items: center;
        }
        a {
          margin-top: 30px;
        }
        p {
          display: flex;
          width: 100%;
          @media screen and (max-width: 576px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
          }
          ul {
            padding-left: 10px;
            li {
              text-align: start;
            }
          }
          > strong {
            width: 20%;
            display: inline-block;
          }
        }
        p:nth-child(3) {
          display: flex;
        }
      }
    }
  }
}
