.reviews {
  padding: 0 0 35px 0;
  background-color: #fff;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    h2 {
      margin: 35px 0;
    }
    .reviews__block {
      width: 100%;
      .icon-style {
        .icon {
          font-size: 1rem;
        }
      }
      .slick-slide {
        > div {
          display: flex;
          padding: 10px;
        }
      }
      .slick-next,
      .slick-prev {
        top: 7%;
      }
      .slick-next {
        @media screen and (max-width: 576px) {
          display: none !important;
        }
      }
      .slick-prev {
        @media screen and (max-width: 576px) {
          display: none !important;
        }
      }
      .testimonial-container {
        background-color: #231a74;
        color: #fff;
        border-radius: 15px;
        margin: 20px auto;
        padding: 50px 80px;
        max-width: 768px;
        position: relative;
        overflow: hidden;
      }
      .fa-quote {
        color: rgba(255, 255, 255, 0.3);
        font-size: 28px;
        position: absolute;
        top: 70px;
      }

      .fa-quote-right {
        left: 40px;
      }

      .fa-quote-left {
        right: 40px;
      }

      .testimonial {
        line-height: 28px;
        text-align: justify;
        margin-top: 10px;
        overflow-y: auto;
        max-height: 250px;
      }

      .user {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user .user-image {
        border-radius: 50%;
        height: 75px;
        width: 75px;
        object-fit: cover;
      }

      .user .user-details {
        margin-left: 10px;
      }

      .user .username {
        margin: 0;
      }

      .user .role {
        font-weight: normal;
        margin: 10px 0;
      }

      @media (max-width: 768px) {
        .testimonial-container {
          padding: 20px 30px;
        }

        .fa-quote {
          display: none;
        }
      }

      .empty-reviews {
        margin: 0 auto;
        width: 270px;
        -webkit-box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.2);
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
#style__scroll_reviews::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  background-color: #fff;
  display: none;
}
#style__scroll_reviews::-webkit-scrollbar-track {
  background-color: #fff;
}
#style__scroll_reviews::-webkit-scrollbar-thumb {
  background-color: #23155b;
  border-radius: 10px;
}
