.contuct-page__comand {
  background-color: #fff;
  margin: 80px 0;
  padding: 50px 0;
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    h4 {
      margin-bottom: 40px;
    }
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #23155B;
        font-weight: 600;
      }
    }
  }
}
