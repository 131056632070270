.custom-radio-checkbox {
  cursor: pointer;
  margin-right: 10px;
  input {
    display: none;
    &:checked ~ svg .path {
      
      stroke-dasharray: 70.5096664428711 9999999;
      stroke-dashoffset: -262.2723388671875;
    }
  }
  svg {
    overflow: visible;
    path {
      fill: none;
      stroke: #5b5b5b;
      stroke-width: 6;
      stroke-linecap: round;
      stroke-linejoin: round;
      transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
      stroke-dasharray: 241 9999999;
      stroke-dashoffset: 0;
    }
  }
}
