$color-border: #23155b;
$bg-color: #fff;

.profile__production__add {
  .error-text{
    color: red;
  }
  &__info {
    background-color: $bg-color;
    padding: 16px;
    width: 100%;
  }
  .add-production-btn {
    font-weight: 500;
    color: $color-border;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
  .css-1xpribl{
    width: 100%;
  }

  &__brand-info,
  &__specialization,
  &__capacity,
  &__equipment {
    > p {
      font-weight: 500;
      color: $color-border;
      margin-bottom: 15px !important;
    }
  }

  .form-control {
    outline: none;
    &:focus {
      box-shadow: none !important;
    }
  }
  p {
    margin-bottom: 0;
  }
  &__submit__btn {
    background-color: transparent;
    color: $color-border;
    border: 1px solid $color-border;
    padding: 12px 20px;
    border-radius: 6px;
    margin: 10px 0;
    &:hover {
      background-color: $color-border;
      color: $bg-color;
    }
  }

  .css-13o7eu2 {
    width: 100%;
  }
  .css-19b7sqg {
    border-bottom: 1px solid #23155b;
  }
  .css-a5mhaz{
    padding: 0;
  }
  .css-edw2yw[aria-selected="true"],
  .css-edw2yw[data-selected] {
    --tabs-color: #1475cf;
    border-color: #23155b;
    border-bottom: 1px solid #fff;
  }
}
.profile__production__add__catalog {
  background-color: $bg-color;
  width: 100%;
}
