.contuct-page {
  .privacy-policy-section {
    font-size: 11px;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
    a {
      strong {
        color: #23155b !important;
      }
    }
  }
  .requisites {
    background-color: #fff;
    margin: 80px 0;
    padding: 50px 0;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    h4 {
      margin-bottom: 40px;
      text-align: center;
    }
    p {
      font-weight: 500;
      color: #222 !important;
    }
  }
  h4 {
    font-size: 25px;
  }
  margin: 20px 0;
  .contuct-page__header__text {
    margin-bottom: 50px;
  }
  .contuct__map {
    background-color: #fff;
    iframe {
      width: 100%;
      height: 400px;
    }
  }
}
