.button {
  width: 235px;
  height: 65px;
  border: 1px solid #3c4151;
  color: #23155b !important;
  text-align: center;
  &:hover {
    background: #23155b;
    border: 1px solid #23155b;
    box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
    color: #fff !important;
    svg line {
      stroke: #ffffff;
    }
  }
}
