.chakra-breadcrumb {
  ol {
    padding-left: 0;
    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      a {
        color: #a0a2a9 !important;
      }
      span{
        box-shadow: none !important;
        width: initial !important;
        height: initial !important;
        margin-bottom: initial !important;
        background-color: initial !important;
      }
    }
  }
}
