.cookies__consent__container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  padding: 7px;
  z-index: 999999;
  max-width: 340px;
  border-radius: 5px;
  padding: 20px 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 600px) {
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
  }
  &__close {
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &__text {
    text-align: center;
    font-size: 14px;
    line-height: 1.55;
    font-weight: 100;
    a {
      text-decoration: underline !important;
    }
  }
  &__btn {
    background-color: #23155b;
    color: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    text-transform: uppercase;
    display: table-cell;
    height: 35px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 13px;
    border: none;
  }
}
