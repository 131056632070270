.contuct-page__form {
  background-color: #fff;
  padding: 50px 0;
  > div {
    display: flex;
    figure {
      width: 50%;
      border-radius: 10px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        overflow: hidden;
      }
    }
    form {
      width: 50%;
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      gap: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }
      a {
        width: 100%;
      }
    }
  }
}
