.constructor__main__wrapper {
  padding: 30px 0 50px;
  &__images__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 400px;
    gap: 10px;
    margin-top: 40px;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 350px;
      gap: 8px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 250px;
      gap: 8px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-auto-rows: 500px;
      gap: 5px;
    }
    figure {
      height: 100%;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
        cursor: zoom-in;
      }
    }
  }
  > .slick-slider {
    > .slick-dots {
      position: absolute;
      top: -130px;
      bottom: unset;
      overflow-x: scroll;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        width: 90px;
        height: 90px;
        border: 2px solid #23155b;
        border-radius: 50%;
        color: #0c2580;
        margin: 10px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        a {
          height: inherit;
          width: inherit;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .slick-active {
        border: 2px solid #e54016;
        color: #171166;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    > .slick-prev {
      top: -75px;
      @media screen and (max-width: 576px) {
        left: 0;
        z-index: 10;
      }
    }
    > .slick-next {
      top: -75px;
      @media screen and (max-width: 576px) {
        right: 0;
        z-index: 10;
      }
    }
  }

  .constructor-wrapper {
    .constructor__deps {
      width: 45%;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      > div {
        width: 400px;
      }
      .title {
        font-weight: 600;
        font-size: 30px;
      }
      .constructor__deps__types {
        .constructor__deps__textile,
        .constructor__deps__colors,
        .constructor__deps__sizes {
          .color-circle {
            width: 17px;
            height: 17px;
            position: relative;
            text-transform: lowercase;
            border: 1px solid grey;
            &:hover:before {
              content: "";
              display: inline-block;
              position: absolute;
              width: 17px;
              height: 17px;
              border-radius: 50%;
              border: 1px solid grey;
              background: url("./../../assets/images/constrImg/x.svg") #fff
                center no-repeat;
            }
          }
          p {
            @media screen and (max-width: 992px) {
              font-size: 11px !important;
            }
          }
          > div {
            display: flex;
            gap: 15px;
            margin-top: 10px;
          }
          span {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            margin-bottom: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            overflow: hidden;
            cursor: pointer;
            @media screen and (max-width: 992px) {
              height: 40px;
            }
            @media screen and (max-width: 768px) {
              height: 38px;
            }
            &.active {
              background: #ffffff;
              -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
              cursor: auto;
              border: 3px solid #23155b;
            }
            &:hover {
              transform: scale(1.2);
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .constructor__items__count {
        a {
          width: 45px;
          height: 45px;
        }
        span {
          width: 90px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid;
          border-top: 1px solid;
        }
        p {
          font-size: 10px;
          margin-top: 5px;
          @media screen and (max-width: 992px) {
            font-size: 11px !important;
          }
        }
      }
      .constructor__items__price {
        p {
          font-size: 10px;
          @media screen and (max-width: 992px) {
            font-size: 11px !important;
          }
        }
      }
      .constructor__btn {
        a {
          height: 45px;
        }
        a:first-child {
          width: 35%;
        }
      }
    }
    .other-tab {
      position: relative;
      opacity: 0;
      animation: sliderTop 0.3s ease-in forwards;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
      figure {
        width: 400px;
        height: 550px;
        overflow: hidden;
        cursor: zoom-in;
        border-radius: 10px;
        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
    .animated-image {
      animation-name: sliderTop;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
    }
    .constructor__design__flip_btn {
      position: absolute;
      top: 25px;
      left: 25px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: rotate(180deg);
      }
    }

    .slick-prev:before {
      content: url("../../assets/images/homePageImg/revArrL.svg");
      font-size: 0;
    }
    .slick-next:before {
      content: url("../../assets/images/homePageImg/revArrR.svg");
      font-size: 0;
    }
    .slick-next,
    .slick-prev {
      background-color: #ffffff78;
      border-radius: 50%;
      z-index: 1;
      width: 30px;
      height: 30px;
    }
    .slick-next {
      right: 10px;
    }
    .slick-prev {
      left: 10px;
    }
    &__card {
      width: 90%;
      position: relative !important;
      background: #ffffff;
      border-radius: 10px;
      padding-top: 60px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 70%;
        padding-top: 15px;
        margin-top: 0;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .constructor__slider {
        width: 20%;
        position: absolute;
        left: -120px;
        top: 21%;
        @media screen and (max-width: 1400px) {
          left: -106px;
        }
        @media screen and (max-width: 1200px) {
          width: 35%;
          left: -200px;
          top: 11%;
        }
        @media screen and (max-width: 996px) {
          width: 45%;
          left: -200px;
          top: 3%;
        }
        @media screen and (max-width: 768px) {
          position: relative;
          left: 0;
          top: 0;
          width: 400px;
          margin: 0 auto;
        }
        @media screen and (max-width: 576px) {
          width: 100%;
        }
        .slick-dots {
          overflow: scroll;
          white-space: nowrap;
          bottom: 123%;
          left: 170%;
          width: 600px;
          &::-webkit-scrollbar {
            display: none;
          }
          @media screen and (max-width: 1200px) {
            bottom: -190%;
            left: 155px;
            display: flex !important;
            flex-direction: column;
            width: 80px;
            height: 725px;
            gap: 10px;
          }
          @media screen and (max-width: 992px) {
            left: 145px;
          }
          @media screen and (max-width: 768px) {
            display: none !important;
          }
          .slick-active {
            border: 1px solid #3e898e;
            border-radius: 10px;
          }
          li {
            width: 70px;
            height: 100px;
            a {
              overflow: hidden;
              img {
                border-radius: 10px;
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .slick-list {
          .slick-slide {
            height: 385px;
            border-radius: 10px;
            overflow: hidden;
            @media screen and (max-width: 768px) {
              height: 550px;
            }
            > div {
              height: 100%;
              .constructor__slider_card {
                overflow: hidden;
                > img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
    @keyframes sliderTop {
      from {
        transform: translateY(-50%);
        opacity: 0.5;
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
.hasActiveSlideBrandDots {
  margin-top: 135px;
}
.hasActiveSlideCardDots{
  margin-top: 50px;
}