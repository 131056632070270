.production__images__slider {
  img,
  video {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .slick-prev {
    left: 15px;
    z-index: 15;
  }
  .slick-next {
    right: 15px;
    z-index: 15;
  }

  .slick-dots {
    position: absolute;
    bottom: -30px;
    li {
      button {
        &::before {
          width: 10px;
          height: 10px;
          content: "";
          border: 2px solid black;
          border-radius: 50%;
        }
      }
    }
  }
}
