.carousel-wrapper {
  -webkit-touch-callout: none; /* Отключение контекстного меню на iOS Safari */
  -webkit-user-select: none; /* Отключение выделения текста */
  -moz-user-select: none; /* Отключение выделения текста в Firefox */
  -ms-user-select: none; /* Отключение выделения текста в Internet Explorer/Edge */
  user-select: none;
  height: 70vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url("https://res.cloudinary.com/drdqjr8r6/image/upload/v1706350904/bg%20images/bg4_jgpu0c.png");
  background-position: 10% 60%;
  background-size: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  @media screen and (max-width: 768px) {
    background-position: center;
    background-size: cover;
    height: 50vh;
  }
}

#drag-container,
#spin-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-10deg);
  transform: rotateX(-10deg);
}

#drag-container a,
#drag-container img {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 200px;
  font-size: 50px;
  text-align: center;
  -webkit-box-shadow: 0 0 8px rgb(255, 255, 255);
  box-shadow: 0 0 8px #fff;
  -webkit-box-reflect: below 10px -webkit-linear-gradient(transparent, transparent, #0005);
  box-reflect: below 10px linear-gradient(transparent, transparent, #0005);
}
#drag-container img {
  object-fit: cover;
}
#drag-container img:hover {
  -webkit-box-shadow: 0 0 15px #fffd;
  box-shadow: 0 0 15px #fffd;
  -webkit-box-reflect: below 10px -webkit-linear-gradient(transparent, transparent, #0007);
  box-reflect: below 10px linear-gradient(transparent, transparent, #0007);
  cursor: grab;
}

#drag-container p {
  font-family: "Ubuntu", sans-serif;
  position: absolute;
  font-size: 30px;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  color: #fff;
}

#ground {
  width: 900px;
  height: 900px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  background: -webkit-radial-gradient(
    center center,
    farthest-side,
    #9993,
    transparent
  );
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
