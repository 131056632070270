.fabric-container {
  .fabric-skeleton {
    display: grid;
    grid-template-columns: 15rem 1fr;
    gap: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      > div:first-child {
        height: 120px;
      }
    }
  }
  .fabric__description {
    padding: 30px 0 50px;
    position: relative;
    &::after {
      content: "";
      background-image: url("https://res.cloudinary.com/drdqjr8r6/image/upload/v1704802284/bg%20images/fabricBg_nmmmfq.png");
      background-size: cover;
      background-position: center;
      opacity: 0.3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
    p {
      text-align: center;
      margin-top: 80px;
    }
  }
  .fabric-content {
    display: grid;
    grid-template-columns: 15rem 1fr;
    gap: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
    }
    .fabric-content-left {
      background-color: #fff;
      padding: 20px;
      height: 110vh;
      @media screen and (max-width: 992px) {
        height: initial;
      }
      .fabric-lists {
        overflow: scroll;
        white-space: nowrap;
        height: 100%;
        margin-bottom: 0;
        width: 100%;
        @media screen and (max-width: 992px) {
          overflow: auto;
          white-space: nowrap;
          height: initial;
        }

        > div {
          margin-bottom: 10px;
          margin-right: 5px;
          cursor: pointer;
          background: #f5f7fb;
          padding-left: 5px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 992px) {
            display: inline-block;
            width: max-content;
            margin-right: 10px;
          }
          &.active {
            border: 1px solid #23155b;
          }
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 8px;
            object-fit: cover;
            @media screen and (max-width: 992px) {
              margin-left: 10px;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          padding-left: 10px;
          background-color: #fff;
          @media screen and (max-width: 992px) {
            height: 5px;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #f5f7fb;
          border-radius: 10px;
        }
      }
    }
    .fabric-content-right {
      background-color: #fff;
      padding: 20px;
      .fabric-content {
        display: flex;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        .fabric-main-slider {
          width: 46%;
          @media screen and (max-width: 1200px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .slick-slider {
            width: 400px;
            height: 500px;
            @media screen and (max-width: 1400px) {
              width: 360px;
            }
            @media screen and (max-width: 1200px) {
              width: 600px;
            }
            @media screen and (max-width: 768px) {
              width: 450px;
            }
            @media screen and (max-width: 576px) {
              width: 100%;
              height: 350px;
            }
            .slick-next {
              right: 15px;
              z-index: 2;
              @media screen and (max-width: 576px) {
                display: none !important;
              }
            }
            .slick-prev {
              left: 15px;
              z-index: 2;
              @media screen and (max-width: 576px) {
                display: none !important;
              }
            }
            .slick-prev,
            .slick-next {
              background-color: #ffffff55;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px solid rgba(255, 255, 255, 0.86);
            }
            .slick-slide {
              > div {
                width: 400px;
                height: 500px;
                @media screen and (max-width: 1200px) {
                  width: 600px;
                }
                @media screen and (max-width: 576px) {
                  width: 100%;
                  height: 350px;
                }
              }
            }
          }
          .slick-dots {
            position: relative;
            overflow-x: scroll;
            overflow-x: auto;
            white-space: nowrap;
            bottom: -5px;
            @media screen and (max-width: 1200px) {
              display: none !important;
            }
            &::-webkit-scrollbar {
              height: 5px;
              background-color: #fff;
            }
            &::-webkit-scrollbar-track {
              background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #f5f7fb;
              border-radius: 10px;
            }
            li {
              width: 60px;
              height: 85px;
              img {
                width: 100%;
                object-fit: cover;
              }
              video {
                width: 100%;
                object-fit: cover;
              }
              a {
                display: flex;
                height: 100%;
              }
              &.slick-active {
                border: 1px solid #1a202c;
              }
            }
          }
        }
        .fabric-info {
          width: 50%;
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
          .slick-slider {
            width: 500px;
            @media screen and (max-width: 1400px) {
              width: 400px;
            }
            @media screen and (max-width: 1200px) {
              width: 600px;
            }
            @media screen and (max-width: 768px) {
              width: 450px;
            }
            @media screen and (max-width: 576px) {
              width: 100%;
            }
            .slick-slide {
              width: 70px !important;
              height: 80px;
              margin-right: 10px;
            }
            .fabric-images {
              display: flex !important;
              justify-content: center;
              gap: 10px;
              width: 70px !important;
              height: 80px;
              .fabricImg {
                width: 70px !important;
                height: 80px;
                object-fit: cover;
                cursor: pointer;
                &.active {
                  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
                  cursor: auto;
                  border: 3px solid #23155b;
                }
              }
            }
          }
          .fabric-description {
            > p {
              span {
                font-size: inherit;
                font-weight: inherit;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .fabric-btn {
            border: 1px solid #23155b;
            padding: 8px 0;
            text-align: center;
            &:hover {
              background: #23155b;
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
