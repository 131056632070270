.about-us-page__section {
  margin: 100px 0;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      background: #ffffff;
      width: 45%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    > div:last-child {
      width: 45%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
    h4 {
      color: #23155b;
      margin-bottom: 20px;
      font-size: 25px;
    }
  }
  > div:nth-child(2) {
    p,
    h4 {
      text-align: end;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row-reverse !important;
    }
  }
  h4,
  p {
    @media screen and (max-width: 768px) {
      text-align: center !important;
    }
  }
}
