$color-border: #23155b;
$bg-color: #fff;

.profilePage-wrapper {
  padding: 20px 0;
  .table > :not(caption) > * > * {
    background-color: inherit;
  }
  .table-responsive {
    .table {
      @media screen and (max-width: 576px) {
        display: flex;
        margin-bottom: 0;
      }
      thead {
        tr {
          @media screen and (max-width: 576px) {
            width: 110px;
          }
          th {
            @media screen and (max-width: 576px) {
              font-size: 10px;
            }
          }
        }
      }
      tbody {
        width: 100%;
        td,
        strong,
        a {
          @media screen and (max-width: 576px) {
            font-size: 10px !important;
          }
        }
      }
    }
    overflow: visible;
    .alert {
      text-align: start;
      @media screen and (max-width: 576px) {
        padding: 0;
      }
    }
  }
  .profile__details {
    box-shadow: 0px 10px 40px rgba(36, 54, 81, 0.15);
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    .css-1yp4ln {
      .profile__add__photo,
      .profile__save__photo,
      .profile__remove__photo {
        position: absolute;
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #23155b;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
      .profile__add__photo {
        bottom: 0;
        right: 12px;
      }
      .profile__save__photo {
        z-index: 1;
        bottom: 24px;
        right: -7px;
      }
      .profile__remove__photo {
        z-index: 1;
        bottom: 55px;
        right: -13px;
      }
    }
    div {
      &:nth-child(2) {
        padding: 0 10px;
        text-align: center;
      }
    }
    button {
      width: 100%;
      padding: 10px 20px;
      border: 1px solid #3c4151;

      &:hover {
        background: #23155b;
        border: 1px solid #23155b;
        box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
        color: #fff !important;
      }
    }
  }
  .profile_form {
    form {
      width: 100%;
      .profile__form_edit {
        background-color: #fff;
        padding: 16px;
      }
      input {
        width: 100%;
        padding: 14px 16px;
        outline: none;
        margin-top: 10px;
        background-color: rgba(36, 62, 149, 0.2);
        border: none;
      }
      button {
        width: 100%;
        height: 65px;
        border: 1px solid #3c4151;
        color: #23155b !important;
        &:hover {
          background: #23155b;
          border: 1px solid #23155b;
          box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
          color: #fff !important;
        }
      }
      .show-password {
        width: 80%;
        label {
          font-size: 12px;
        }
        input {
          width: 20px;
          margin-right: 10px;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .progress-show-wrapper {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
    }
    &__btn {
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #23155b;
      padding: 5px 15px;
      color: #23155b;
      border-radius: 5px;
      margin-bottom: 15px;
      &:hover {
        background-color: #23155b;
        color: #fff;
      }
    }
  }
  .content-main {
    .content-header {
      button {
        background-color: #23155b;
        color: #fff;
        border: #23155b;
        width: 213px;
        padding: 12px 20px;
        border-radius: 6px;
      }
    }

    .css-edb818[data-checked] {
      background-color: #fff;
      color: #1475cf;
      border-color: #1475cf;
    }
  }
  .product__sizes__wrapper {
    > div:nth-child(3) {
      overflow-x: scroll;
      white-space: nowrap;
      gap: 10px;
      height: 80px;
    }
  }
  .product__color__wrapper,
  .product__sizes__wrapper {
    svg {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
}
.nav-pills .nav-link {
  color: #23155b;
}
.nav-pills .nav-link.active {
  background: #23155b !important;
  border: 1px solid #23155b;
}
.badge2 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: #3c4151;
}
