.footer {
  padding: 40px 0;
  @media screen and (max-width: 768px) {
    padding: 0 0 40px;
  }
  background-color: #fff;
  > .container {
    padding: 40px 0 0 0;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .footer__grid {
    display: grid;
    grid-template-areas: "l l l n n n n n n n n n";
    @media screen and (max-width: 992px) {
      grid-template-areas:
        "l l l l l l l l l"
        "n n n n n n n n n";
    }

    .footer__logo {
      grid-area: l;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 576px) {
        a {
          width: 60%;
        }
      }
    }
    .footer__navs {
      grid-area: n;
      display: grid;
      grid-template-areas: "one one one two two two three three three";
      @media screen and (max-width: 576px) {
        grid-template-areas:
          "one one one two two two"
          "one one one two two two"
          "three three three three three three";
        padding: 0 30px;
      }
      &__one {
        grid-area: one;
      }
      &__two {
        grid-area: two;
        @media screen and (max-width: 576px) {
          margin-bottom: 28px;
        }
      }
      &__three {
        grid-area: three;
        @media screen and (max-width: 576px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      ul {
        margin-bottom: 10px;
        li {
          margin-bottom: 10px;
          img {
            width: 25px;
            height: 25px;
            border-radius: 6px;
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    .active {
      color: #23155b !important;
      font-size: 17px;
      font-weight: bold;
    }
    a:hover {
      color: #23155b !important;
    }
    hr {
      margin-top: 40px;
      margin-bottom: 20px;
      color: #23155b;
    }
  }
  .copyright {
    margin-top: 60px;
    p {
      font-size: 11px !important;
    }
  }
}
