@mixin figure-img {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile__production__add__catalog {
  border: 1px solid #fff;
  &__header__text {
    font-weight: 500;
    color: #23155b;
    margin-bottom: 15px !important;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 298px;
    gap: 2px;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 200px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 190px;
    }
    figure {
      height: 100%;
      position: relative;
      > img:first-child {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .productionCatalogCloseBtn {
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 3;
        cursor: pointer;
      }
    }
    > div:first-child {
      border: 2px dashed #1475cf;
      cursor: pointer;
    }
    &__cards {
      .catalog__card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 40px;
        overflow: hidden;
        .closeBtn {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 3;
          cursor: pointer;
        }
      }
    }
  }
  &__models__grid {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 238px;
    gap: 2px;
    margin: 30px 0;
    .addProductionModels {
      border: 2px dashed #1475cf;
      cursor: pointer;
      > div {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 200px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 190px;
    }
    figure {
      position: relative;
      height: 100%;
      @include figure-img;
      span {
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        top: 10px;
        left: 10px;
      }
      .productionCatalogCloseBtn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        cursor: pointer;
      }
    }
  }
}
