@mixin ball {
  @include center;
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.applications__wrapper {
  &__background {
    &::before {
      content: "";
      background-image: url("https://res.cloudinary.com/drdqjr8r6/image/upload/v1700219127/bg%20images/azazefir_chat_provides_a_convenient_and_effective_way_to_find_w_3cc801c2-9b20-4bc1-902a-951df3699731_g4gkc7.png");
      background-size: cover;
      background-position: center;
      opacity: 0.4;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
    p {
      text-align: center;
      margin-top: 80px;
    }
    .applications__content__header {
      padding: 30px 0 50px;
    }
  }
  &__container {
    padding: 80px 0;
    position: relative;
    .applications__content {
      &__body {
        &__category {
          background-color: #fff;
          overflow: auto;
          ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            &::-webkit-scrollbar-track {
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #23155b;
              border-radius: 10px;
            }
            @media screen and (max-width: 992px) {
              display: flex;
              gap: 10px;
            }
            svg {
              color: #4eac6d;
            }
            li {
              cursor: pointer;
              margin-bottom: 10px;
              background: #f5f7fb;
              padding: 5px 10px;
              border-radius: 6px;
              &.active {
                background: #b5b9c0;
              }
              &.disabled {
                pointer-events: none;
                color: #b5b9c0;
              }
              p {
                width: max-content;
                margin-bottom: 0;
              }
              @media screen and (max-width: 992px) {
                display: flex;
                align-items: center;
              }
            }
          }
        }
        &__wrapper {
          display: flex;
          gap: 10px;
          position: relative;
          @media screen and (max-width: 992px) {
            flex-direction: column;
          }
          > div {
            background: #ffffff;
          }
          &__contacts {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            width: 30%;
            @media screen and (max-width: 992px) {
              width: 100%;
              position: absolute;
              z-index: 2;
            }

            .css-xl71ch {
              display: flex;
              position: relative;
              .css-8anuwp {
                display: flex;
                position: absolute;
                right: -10px;
                top: -10px;
                align-items: center;
                justify-content: center;
                background-color: #eaf1fb !important;
              }
            }
            .popover-btn {
              position: absolute;
              top: 8px;
              right: 12px;
              svg {
                width: 14px;
                height: 14px;
                color: #4eac6d;
              }
            }
            .css-1ews2c8 {
              svg {
                width: 14px;
                height: 14px;
                color: #4eac6d;
              }
              p {
                font-size: 10px;
              }
            }
            > div:first-child {
              padding: 20px;
              border-bottom: 1px solid #eaf1fb;
            }
            > div:nth-child(2) {
              cursor: pointer;
              margin: 25px;
              height: 100vh;
              overflow: auto;
              @media screen and (max-width: 992px) {
                height: 110vh;
              }
              > div {
                > div {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  .css-10qgffp {
                    width: 100%;
                  }
                  p {
                    width: max-content;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          &__lists {
            background-color: #fff;
            width: 80%;
            @media screen and (max-width: 992px) {
              width: 100%;
            }
            .lists-container {
              height: 100vh;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            > div:first-child {
              display: grid;
              grid-template-columns: 180px 1fr;
              gap: 10px;
              background-color: #eaf1fb00;
              padding: 20px;
              border-bottom: 1px solid #eaf1fb;
              @media screen and (max-width: 992px) {
                grid-template-columns: none;
                grid-template-areas:
                  "a a a a a a a a a"
                  "c . . . . . . . b";
                .back-btn {
                  grid-area: b;
                }
                .form-control {
                  grid-area: a;
                }
                .chat-btn {
                  grid-area: c;
                }
              }

              input {
                outline: none;
                padding: 5px;
                background-color: #eaf1fb;
              }
              svg {
                cursor: pointer;
              }
            }
            > div:nth-child(2) {
              height: 100vh;
              .messages {
                padding: 20px;
                color: rgba(255, 255, 255, 0.5);
                position: relative;
                width: 100%;
                overflow-y: auto;
                height: 100vh;
                .chat-info {
                  border-bottom: 1px solid #eaf1fb;
                  padding-bottom: 20px;
                  p {
                    color: #1a202c;
                    margin-bottom: 0;
                  }
                }
                .message {
                  padding: 10px 15px;
                  border-radius: 10px 10px 10px 0;
                  background: rgba(0, 0, 0, 0.3);
                  margin: 8px 0;
                  line-height: 1.4;
                  position: relative;
                  h5 {
                    margin-bottom: 0;
                  }
                  strong {
                    font-weight: 500;
                  }
                  &__images {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    figure {
                      height: 200px;
                      width: 300px;
                      overflow: hidden;
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }
                  }
                  .message-date,
                  .message-city {
                    margin-bottom: 0;
                    font-size: 10px !important;
                  }
                  .message-icons {
                    p {
                      margin-bottom: 0;
                      font-size: smaller;
                    }
                    > div {
                      cursor: pointer;
                      font-size: smaller;

                      svg {
                        width: 14px;
                        height: 14px;
                        color: #4eac6d;
                      }
                    }
                  }

                  &::before {
                    content: "";
                    position: absolute;
                    bottom: -6px;
                    border-top: 6px solid rgba(0, 0, 0, 0.3);
                    right: 0;
                    border-right: 7px solid transparent;
                  }

                  .avatar {
                    position: absolute;
                    z-index: 1;
                    bottom: -20px;
                    left: -45px;
                    border-radius: 30px;
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    margin: 0;
                    padding: 0;
                    .css-1yp4ln {
                      height: 100%;
                      span {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                  &.message-personal {
                    color: #1a202c;
                    text-align: left;
                    background: #f5f7fb;
                    border-radius: 10px 10px 10px 0;
                    margin-left: 50px;

                    .message-icons {
                      button {
                        cursor: pointer;
                        font-size: smaller;

                        svg {
                          width: 14px;
                          height: 14px;
                          color: #4eac6d;
                        }
                        &:disabled {
                          pointer-events: none;
                          opacity: 0.5;
                        }
                      }
                    }
                    &::before {
                      right: auto;
                      left: 0;
                      border-left: none;
                      border-right: 5px solid transparent;
                      border-top: 4px solid #eaf1fb;
                      bottom: -4px;
                    }
                    h5 {
                      color: #23155b;
                      margin-bottom: 5px;
                    }
                    > p {
                      margin-bottom: 0;
                    }
                  }
                }
                .message-chat {
                  padding: 10px 15px;
                  border-radius: 10px 10px 10px 10px;
                  background: rgba(0, 0, 0, 0.3);
                  margin: 8px 0;
                  line-height: 1.4;
                  position: relative;
                  .message-date,
                  .message-city {
                    margin-bottom: 0;
                    font-size: 10px !important;
                  }
                  h5 {
                    margin-bottom: 0;
                  }
                  strong {
                    font-weight: 500;
                  }
                  &.message-personal-chat {
                    background: rgb(67, 74, 168);
                    color: #f4f4f4;
                    h5 {
                      color: #f4f4f4;
                      margin-bottom: 5px;
                    }
                    > p {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            > div:nth-child(3) {
              display: flex;
              flex-direction: column;
              .typing-loader {
                position: absolute;
                bottom: 90px;
                left: 20px;
              }
              .input-fields {
                padding: 20px;
                border-top: 1px solid #eaf1fb;
                > div {
                  display: flex;
                  align-items: center;
                  svg {
                    cursor: pointer;
                  }
                  input,
                  select {
                    outline: none;
                    padding: 6px;
                    background-color: #eaf1fb;
                    color: #54585e;
                  }
                  button {
                    height: 35.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  h5 {
    margin-bottom: 0;
  }
  .applicationsBtn {
    background-color: #fff;
    color: #23155b;
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid #23155b;
    &:hover {
      background-color: #23155b;
      color: #fff;
    }
  }
}

.welcome-chat-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  p {
    width: 50%;
  }
}

.typing-loader {
  text-align: start;
  color: grey;
  font-size: smaller;
}
.typing-loader span {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: black;
  border-radius: 20px;
  animation: loader 0.8s infinite alternate;
}
.typing-loader span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.typing-loader span:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}
