.colors__by__image {
  .slick-slider {
    width: 400px;
    height: 75px;
    @media screen and (max-width: 1200px) {
      width: 300px;
    }
    @media screen and (max-width: 576px) {
      width: 250px;
    }
    .slick-prev {
      top: 56%;
      @media screen and (max-width: 576px) {
        left: -25px;
      }
    }
    .slick-next {
      top: 56%;
      @media screen and (max-width: 576px) {
        right: -25px;
      }
    }
    .slick-list {
      height: 75px;
      .slick-track {
        height: 75px;
        .slick-slide {
          margin: 0 3px;
        }
      }
    }
    .colors__by__image__card {
      width: 50px;
      height: 75px;
      &.active {
        -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        cursor: auto;
        border: 3px solid #23155b;
      }
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        @media screen and (max-width: 576px) {
          height: 100%;
        }
      }
    }
  }
}
