.deportment-cards {
  background-color: #fff;
  > div {
    display: grid;
    grid-template-areas:
      "catalog catalog catalog production fulfilment"
      "catalog catalog catalog application design";
    grid-auto-rows: 330px;
    gap: 10px;

    @media screen and (max-width: 768px) {
      grid-template-areas:
        "catalog catalog catalog catalog catalog"
        "production production production production production"
        "fulfilment fulfilment fulfilment fulfilment fulfilment"
        "application application application application application"
        "design design design design design";
    }
    > div:first-child {
      grid-area: production;
    }
    > div:nth-child(2) {
      grid-area: catalog;
    }
    > div:nth-child(3) {
      grid-area: fulfilment;
    }
    > div:nth-child(4) {
      grid-area: application;
    }
    > div:nth-child(5) {
      grid-area: design;
    }
    .cards {
      background-size: cover;
      background-position: center;
      position: relative;
      z-index: 0;
      @media screen and (max-width: 576px) {
        margin: 0 10px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0),
          rgba(0, 0, 0, 0.7)
        );
        z-index: -1;
      }
      h4 {
        color: #ffffff;
        font-size: 28px !important;
        font-weight: 400 !important;
        margin-bottom: 8px;
        @media screen and (max-width: 576px) {
          font-size: 1.15rem !important;
          margin-top: 10px;
        }
      }

      .icon-links {
        transform: translateZ(40px) scale(0.8);
        color: #141850;
      }
      a {
        width: max-content;
        padding: 5px 15px;
        color: #fff !important;
        border: 1px solid #ffffff;
        &:hover {
          border: 1px solid #ffffff;
          background-color: #ffffff;
          color: #000000 !important;
        }
      }
    }
  }
}
