.new-collection {
  background-color: #fff;
  margin-top: 50px;
  > div {
    > div {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: 390px;
      margin: 20px 0;
      gap: 10px;
      @media screen and (max-width: 1400px) {
        grid-auto-rows: 350px;
      }
      @media screen and (max-width: 1200px) {
        grid-auto-rows: 360px;
      }
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 250px;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 250px;
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 280px;
        padding: 10px;
      }
    }
    h2 {
      text-align: center;
    }
    .newCollection-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
