.mobile-home-page-links {
  .contact-links {
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 10%;
    canvas {
      width: 50px;
    }
    > a {
      width: 100%;
      > div {
        width: 100% !important;
      }
    }
  }
}
