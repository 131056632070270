.catalog {
  margin-top: 20px;
  .catalog__sort {
    .catalog__sort__search__sort {
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
  p {
    margin: 0;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.02em;
  }
  h4 {
    margin: 0;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #23155b;
    text-align: center;
  }
  .sort-popup {
    cursor: pointer;
    p {
      width: 100%;
    }
  }
}
