.catalog__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  .page-item {
    margin: 0 5px;
    list-style: none;

    &.active {
      a {
        color: white !important;
        background-color: #23155b;
        border-color: #23155b;
      }
    }

    a {
      color: #23155b;
      background-color: #fff;
      border: 1px solid #dee2e6;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #23155b;
        color: #fff !important;
      }
    }
  }
}
