.production {
  .production-cards {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &__card {
      width: 300px;
      display: inline-block;
      height: 340px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 1400px) {
        width: 250px;
        height: 300px;
      }
      @media screen and (max-width: 1200px) {
        height: 250px;
      }
      @media screen and (max-width: 992px) {
        margin-right: 10px;
        width: 200px;
        height: 260px;
      }
      @media screen and (max-width: 768px) {
        width: 150px;
        height: 200px;
      }
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .production__constructor {
    margin: 90px 0;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      text-align: center;
    }
    h4 {
      > strong {
        color: #23155b;
      }
    }
  }
}
