.contuctUsSection {
  background-color: #fff;
  padding: 60px 0;
  position: relative;
  margin-top: 85px;
  margin-bottom: 100px;
  .react-tel-input {
    height: 69px !important;
    .selected-flag {
      padding-left: 17px !important;
      @media screen and (max-width: 576px) {
        background: #f6f6f6 !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0);
      }
    }
    .flag-dropdown.open .selected-flag {
      background: rgba(255, 255, 255, 0);
    }
  }
  > div {
    position: relative;
    z-index: 1;
    h2 {
      margin-bottom: 70px;
      @media screen and (max-width: 992px) {
        text-align: center;
      }
    }
    p {
      margin: 40px 0 100px 0;
      font-size: 12px;
      @media screen and (max-width: 992px) {
        text-align: center;
      }
      a {
        strong {
          color: #23155b !important;
        }
      }
    }
    form {
      > div {
        width: 35%;
        @media screen and (max-width: 768px) {
          width: 75%;
        }
        @media screen and (max-width: 992px) {
          width: 75%;
        }
        input {
          width: 100%;
          height: 69px;
          background: #ffffff;
          -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
          -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
          box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25) !important;
          outline: none;
          border: none;
          border-radius: none !important;
          margin-top: 10px;
          padding-left: 20px;
          @media screen and (max-width: 576px) {
            background: #f6f6f6 !important;
            -moz-box-shadow: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }
        }
      }
      a {
        @media screen and (max-width: 768px) {
          width: 75%;
        }
        @media screen and (max-width: 992px) {
          width: 75%;
        }
      }
    }
  }
  .contuctUsBgImg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35%;
    opacity: 0.5;
    @media screen and (min-width: 1400px) {
      width: 30%;
    }
    @media screen and (max-width: 992px) {
      width: 65%;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}
