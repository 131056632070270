.shopping-cart {
  padding-top: 20px;
  a {
    position: relative;
    z-index: 1;
  }
  .container {
    .shopping-cart__header {
      margin-bottom: 80px;
      h2 {
        margin: 0;
      }
      a:last-child {
        color: #a0a2a9 !important;
        img {
          margin-right: 10px;
        }
      }
      a:first-child {
        img {
          margin-right: 10px;
        }
      }
      > div {
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          figure {
            margin: 0;
            img {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .shopping-cart__items {
      .fulfillment_items {
        ul {
          width: 70%;
          list-style-position: inside;
          padding-left: 0;
        }
      }
      .shopping-cart__item {
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 10px 40px rgba(36, 54, 81, 0.15);
        position: relative;
        .shopping-cart__item__ff_l {
          width: 68%;
          @media screen and (max-width: 1400px) {
            width: 65%;
          }
          @media screen and (max-width: 576px) {
            width: 100%;
          }
        }
        .shopping-cart__item__ff_r {
          width: 32%;
          @media screen and (max-width: 1400px) {
            width: 35%;
          }
          @media screen and (max-width: 1200px) {
            width: 75%;
          }
          @media screen and (max-width: 992px) {
            width: 100%;
          }
          @media screen and (max-width: 768px) {
            width: 65%;
          }
          @media screen and (max-width: 576px) {
            width: 100%;
          }
        }
        .shopping-cart__item_description {
          width: 50%;
          @media screen and (max-width: 1200px) {
            width: 75%;
            margin-bottom: 20px;
          }
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
        > div {
          @media screen and (max-width: 576px) {
            width: 100%;
          }
        }
        figure {
          margin-right: 25px;
          height: 235px;
          width: 25%;
          @media screen and (max-width: 1400px) {
            width: 35%;
          }
          @media screen and (max-width: 1200px) {
            width: 25%;
          }
          @media screen and (max-width: 768px) {
            margin-right: 0;
            width: 35%;
          }
          @media screen and (max-width: 576px) {
            margin-right: 0;
            width: 50%;
          }
          @media screen and (max-width: 400px) {
            margin-right: 0;
            width: 45%;
            height: 190px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .shopping-cart__items_item {
          @media screen and (max-width: 768px) {
            width: 300px;
          }
          @media screen and (max-width: 576px) {
            width: 100%;
          }
          > a:first-child span:first-child {
            color: #23155b;
            font-weight: 600;
          }
          > p:nth-child(n + 2) strong {
            font-weight: 600;
            width: 75px;
            display: inline-block;
          }
          .color-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: lowercase;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid #a0a2a9;
            font-size: 10px;
          }
        }
        .shopping-cart__count {
          @media screen and (max-width: 1200px) {
            width: 75%;
          }
          @media screen and (max-width: 992px) {
            width: 100%;
          }
          .shopping-cart__items_count {
            width: 285px;
            @media screen and (max-width: 768px) {
              width: 300px;
            }
            @media screen and (max-width: 576px) {
              width: 100%;
            }
            img {
              cursor: pointer;
              &:hover {
                transform: scale(1.1);
                transition: ease-in-out 1s;
              }
            }
          }
        }

        .shopping-cart__items_price {
          .shopping-cart__items_price_width {
            width: 300px;
            @media screen and (max-width: 576px) {
              width: 100%;
            }
          }
          p {
            font-size: 12px;
            width: 300px;
            // @media screen and (max-width: 1200px) {
            //   width: 150px;
            // }
            // @media screen and (max-width: 992px) {
            //   width: 180px;
            // }
            @media screen and (max-width: 576px) {
              font-size: 14px;
            }
            span {
              font-weight: 600;
              @media screen and (max-width: 576px) {
                width: 50%;
              }
            }
          }
          width: 100%;

          img {
            cursor: pointer;
            z-index: 1;

            @media screen and (max-width: 768px) {
              position: absolute;
              top: 25px;
              right: 108px;
            }
            @media screen and (max-width: 576px) {
              position: absolute;
              top: 25px;
              right: 23px;
            }
            &:hover {
              transform: scale(1.1);
              transition: ease-in-out 1s;
            }
          }
        }
      }
    }
    .shopping-cart__payment {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 24px;
      position: sticky;
      top: 110px;
      button {
        border: 1px solid #23155b;
        width: 100%;
        padding: 10px 0;
        color: #23155b;
        &:hover {
          background-color: #23155b;
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          color: #a0a2a9;
        }
      }
      a {
        width: 100%;
      }
    }

    .delivery-payment-container {
      section {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 10px 40px rgba(36, 54, 81, 0.15);
      }
    }
  }
}
