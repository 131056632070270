.progress-steps {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  @media screen and (max-width: 576px) {
    margin-bottom: 70px;
    padding: 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: #f3e7f3;
      height: 4px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      background: #23155b;
      height: 4px;
      width: var(--progress-width, 0);
      top: 50%;
      transition: 0.4s ease;
      transform: translateY(-50%);
      left: 0;
      z-index: -1;
    }
  }
  &__step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 3px solid #f3e7f3;
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &.completed {
      border-color: #23155b;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__wrapper {
    position: relative;
    cursor: pointer;
  }
  &__label {
    position: absolute;
    top: 66px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    background-color: #f3e7f3;
    border-radius: 6px;
    cursor: pointer;
    z-index: 2;
  }
  &__lbl {
    font-size: 13px;
    color: #23155b;
    p{
      width: 100%;
      margin-bottom: 0;
      padding: 6px;
    }
  }
  &__count {
    font-size: 19px;
    color: #f3e7f3;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  &__checkmarks {
    font-size: 26px;
    font-weight: 600;
    color: #0b9c08;
    -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-46deg);
  }
}
