@import "./pages/styles/slick-theme.scss";
@import "./pages/styles/slick.scss";

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/fonts/ubuntu/Ubuntu-Bold.ttf") format("truetype");
}

a {
  font-weight: 300;
  font-family: "Roboto-regular";
  color: #222 !important;
  text-decoration: none !important;
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem !important;
  }
}

h2,
h1 {
  font-weight: 500;
  font-size: 33px !important;
  line-height: 40px;
  font-family: "Roboto";
  @media screen and (max-width: 992px) {
    font-size: 25px !important;
  }
  @media screen and (max-width: 768px) {
    text-align: center !important;
    font-size: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.15rem !important;
  }
}
h4 {
  font-weight: 600;
  font-size: 1rem !important;
  @media screen and (max-width: 576px) {
    font-size: 0.9rem !important;
  }
}
h5 {
  font-weight: 500;
  font-size: 25px;
  @media screen and (max-width: 576px) {
    font-size: 1.15rem;
  }
}
h6 {
  font-weight: 500 !important  ;
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem !important;
  }
}
strong {
  @media screen and (max-width: 992px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem !important;
  }
}
p,
span {
  @media screen and (max-width: 992px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem !important;
  }
}
span {
  font-family: "Roboto-regular";
}
.contact-links {
  position: fixed;
  z-index: 99999;
  right: -120px;
  top: 43%;
  display: block;
  width: 14%;
  cursor: grab;
  @media screen and (max-width: 1300px) {
    right: -95px;
  }
  @media screen and (max-width: 1100px) {
    right: -85px;
  }
  @media screen and (max-width: 992px) {
    right: -65px;
  }
  > div {
    > div {
      width: 35% !important;
      @media screen and (max-width: 576px) {
        width: 35px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

#style__scroll::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}
#style__scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
#style__scroll::-webkit-scrollbar-thumb {
  background-color: #23155b;
  border-radius: 10px;
}

#style__scroll__profile::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
#style__scroll__profile::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
#style__scroll__profile::-webkit-scrollbar-thumb {
  background-color: #23155b;
  border-radius: 10px;
}

#style__scroll__chat::-webkit-scrollbar {
  display: none;
}
#style__scroll__chat::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
#style__scroll__chat::-webkit-scrollbar-thumb {
  background-color: #f4f5f7;
  border-radius: 10px;
}

.cart-error-message {
  color: red !important;
  font-size: 11px !important;
  margin-bottom: 0;
}
.cart-border-error {
  border: 1px solid red !important;
}

.production__design__wrapper {
  margin: 20px 0;

  .css-1phd9a0 {
    width: 30%;
    height: 200px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .chakra-card {
    gap: 15px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .css-18j379d {
      @media screen and (max-width: 768px) {
        font-size: 25px !important;
      }
    }
    h2 {
      margin-bottom: 0;
    }
    span,
    strong,
    p {
      font-size: 0.8125rem !important;
    }

    &__footer {
      a {
        width: 175px;
        height: 35px;
        border: 1px solid #23155b;
        position: relative;
        -webkit-transition: background 0.3s, color 0.3s;
        transition: background 0.3s, color 0.3s;
        &:hover {
          background: #23155b;
          color: #fff !important;
        }
        svg {
          &:first-child {
            color: #118638;
            &:hover {
              color: #118638 !important;
            }
          }
        }
      }
    }

    &__body {
      a {
        width: initial;
        height: initial;
        border: none;
        color: #118638 !important;
        border: 1px solid #118638;
        border-radius: 10px;
        padding: 0 4px;
        &:hover {
          background: none;
          box-shadow: none;
          color: #23155b !important;
        }
      }
    }
    .slick-slider {
      width: 30%;
      height: 200px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
.css-woqubx tr:nth-of-type(odd) td {
  background: #dce6ff !important;
  border: none !important;
}
.css-12y4u6o,
.css-1sa2h46 {
  border-color: #dce6ff !important;
}
.production__design-links {
  cursor: pointer;
  figure {
    border: 2px solid #23155b;
    width: 90px !important;
    height: 90px;
    margin: 10px auto;
    border-radius: 50%;
    color: #0c2580;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
    &.active {
      color: #171166;
      border-color: #ffffff;
      transform: scale(1.1);
      border: 2px solid #e54016;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -30px;
    li {
      button {
        &::before {
          width: 10px;
          height: 10px;
          content: "";
          border: 2px solid black;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-prev {
    @media screen and (max-width: 600px) {
      left: -5px;
    }
  }
  .slick-next {
    @media screen and (max-width: 600px) {
      right: -5px;
    }
  }
}
.slick-prev:before {
  content: url("./assets/images/homePageImg/revArrL.svg");
}
.slick-next:before {
  content: url("./assets/images/homePageImg/revArrR.svg");
}

.custom__save__btn {
  position: relative;
  .custom__save__btn__info {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 5;
  }
  .save__btn__info {
    border: 1px solid #23155b;
    background-color: #fff;
    color: #23155b;
    padding: 7px;
    width: initial;
    margin: 0;
    border-radius: 10px;
    &:hover {
      background-color: #23155b;
      color: #fff;
    }
  }
}

.profile__info__title {
  font-size: 9px !important;
  padding: 5px;
  background-color: #d7eef7;
  border-radius: 5px;
  @media screen and (max-width: 500px) {
    p {
      font-size: 9px !important;
    }
  }
}

.css-8anuwp {
  background-color: #d7eef7 !important;
  color: #23155b !important;
}

.css-qlig70 {
  .card-body {
    padding: 0 !important;
    .card-body-gender {
      border: 1px solid #23155b;
      padding: 5px;
      font-size: smaller;
      &:hover {
        background-color: #23155b;
        color: #fff;
      }
      &.active {
        background-color: #23155b;
        color: #fff;
      }
    }
  }

  .product__color__wrapper,
  .product__sizes__wrapper {
    svg {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
  .product__color__wrapper {
    border-radius: 10px;
    border: 1.5px solid #1475cf;
    padding: 5px;
    > div {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: 70px;
      gap: 10px;

      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(6, 1fr);
      }
      > div {
        border-radius: 10px;
      }
      svg {
        width: 20px;
        height: 20px;
        color: #1475cf;
      }
    }
  }
  .btn-primary {
    background-color: transparent;
    color: #23155b;
    border: 1px solid #23155b;
    padding: 12px 20px;
    border-radius: 6px;
    margin: 10px 0;
    &:hover {
      background-color: #23155b;
      color: #fff;
    }
  }
}
.main-profile-image {
  &__content {
    border: 2px dashed #1475cf;
    padding: 6px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .uploadImage {
    width: 80px;
    height: 80px;
    color: #1475cf;
  }
  @media screen and (max-width: 500px) {
    p {
      font-size: 9px !important;
    }
  }
}
.profile-images-container {
  .image-preview-banner-container {
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      color: #1475cf;
    }
  }
  .image-preview-colorPicker-container {
    height: 300px;
    width: 250px;
    border: 1px solid #1475cf;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .image-preview-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    height: 80px;
    border: 1px solid #1475cf;
    padding-right: 10px;
    border-radius: 10px;
    width: 100%;

    figure {
      width: 70px;
      height: 100%;
      overflow: hidden;
      margin-bottom: 0;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .add__colors__image {
      border: 1px solid #1475cf;
      padding: 3px 8px;
      font-size: 14px;
      border-radius: 10px;
      @media screen and (max-width: 576px) {
        font-size: 10px;
      }
      svg {
        width: 20px;
        height: 20px;
        color: #1475cf;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

.constructor__open__modal {
  width: 235px;
  height: 45px;
  border: 1px solid #3c4151;
  color: #23155b !important;
  text-align: center;
  font-weight: 500;
  &:hover {
    background: #23155b;
    border: 1px solid #23155b;
    box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
    color: #fff !important;
    svg line {
      stroke: #ffffff;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem;
  }
}

.welcome-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #c1d1c6;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #4eac6d;
    width: 50px;
    height: 50px;
  }
}

.ellipsis-text,
.chakra-breadcrumb__link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.b24-widget-button-position-bottom-right {
  right: 20px !important;
  bottom: 20px !important;
}
.animated-button {
  svg {
    &:nth-child(2) {
      color: #118638;
      position: absolute;
      right: -10px;
      bottom: -10px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      -webkit-transform: scale(1);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      animation: pulse 2s infinite;
      -webkit-animation: pulse 2s infinite;
      -webkit-animation-play-state: running;
      animation-play-state: running;
    }
    @keyframes pulse {
      0% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 #118638;
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(15, 2, 99, 0);
      }
    }
    @media screen and (max-width: 768px) {
      box-shadow: none !important;
    }
    @-webkit-keyframes pulse {
      0% {
        -webkit-transform: scale(0.7);
        -webkit-box-shadow: 0 0 0 0 #118638;
      }

      70% {
        -webkit-transform: scale(1);
        -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        -webkit-transform: scale(0.7);
        -webkit-box-shadow: 0 0 0 0 rgba(15, 2, 99, 0);
      }
    }
    @media screen and (max-width: 768px) {
      -webkit-box-shadow: none !important;
    }
  }
}
.productionBanner__text {
  input,
  textarea {
    outline: none;
    padding: 3px;
    border-radius: 0.4rem;
  }
}

.catalog-items-btn {
  width: 235px;
  height: 45px;
  border: 1px solid #3c4151;
  color: #23155b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  &:hover {
    background: #23155b;
    border: 1px solid #23155b;
    box-shadow: 0px 16px 33px rgba(2, 2, 2, 0.46);
    color: #fff;
    svg line {
      stroke: #ffffff;
    }
  }
}
.disabled-btn {
  pointer-events: none;
  cursor: not-allowed;
  color: #5b5a5e !important;
}

.catalog__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
  overflow: hidden;
  .favorite {
    position: absolute;
    left: 6px;
    top: 6px;
    z-index: 3;
    cursor: pointer;
    @media screen and (max-width: 576px) {
      top: 4px;
    }
  }
  .new-clothes {
    position: absolute;
    right: 6px;
    top: 6px;
    text-transform: uppercase;
  }
  .catalog__card_description {
    p,
    h4 {
      font-size: 13px !important;
      text-transform: uppercase;
      color: initial;
      font-weight: 200;
      text-align: start;
      margin-bottom: 0;
    }
  }
}

// paymentPage tabs
.css-edw2yw[aria-selected="true"] {
  color: #007382;
  font-weight: 600;
  font-family: "Roboto-regular";
}

.saleType {
  p {
    border-radius: 2px;
    color: #fff;
    padding: 0 5px;
    font-size: 13px !important;
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 200;
    margin-bottom: 0;
  }
  p:first-child {
    background-color: #535df0;
  }
  p:nth-child(2) {
    background-color: #c50db2;
  }
}
