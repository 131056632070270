.design-department {
  .design__description {
    position: relative;
    padding: 30px 0 50px;
    &::after {
      content: '';
      background-image: url('https://res.cloudinary.com/drdqjr8r6/image/upload/v1691645475/bg%20images/hp7_m6bldb.jpg');
      background-size: cover;
      background-position: center;
      opacity: 0.3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
    p {
      text-align: center;
      margin-top: 80px;
    }
  }
}
